export const MQ_SMALL_ONLY: string =
  "(min-width: 1px) and (max-width: 37.499rem)";
export const MQ_MEDIUM: string = "(min-width: 37.5rem)";
export const MQ_MEDIUM_ONLY: string =
  "(min-width: 37.5rem) and (max-width: 63.999rem)";
export const MQ_LARGE: string = "(min-width: 64rem)";
export const MQ_LARGE_ONLY: string =
  "(min-width: 64rem) and (max-width: 77.499rem)";
export const MQ_X_LARGE: string = "(min-width: 77.5rem)";
export const MQ_X_LARGE_ONLY: string =
  "(min-width: 77.5rem) and (max-width: 89.999rem)";
export const MQ_XX_LARGE: string = "(min-width: 90rem)";
