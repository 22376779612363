// Modified to TS From https://www.joshwcomeau.com/snippets/javascript/debounce/

// Debounce fires when the action is ended (like when resize ended)
export const debounce = (callback: (...args: any[]) => void, wait: number) => {
  let timeoutId: number | null = null;
  return (...args: any[]) => {
    if (timeoutId !== null) window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

// Throttle fires ever X ms, during the event
export const throttle = (callback: (...args: any[]) => void, wait: number) => {
  let timeoutId: number | null = null;
  return (...args: any[]) => {
    if (timeoutId === null) {
      callback(...args);
      timeoutId = window.setTimeout(() => {
        timeoutId = null;
      }, wait);
    }
  };
};
